let resumeData = {
    "main": {
      "address": {
        "street": "Madison Eve",
        "city": "Fairfield",
        "state": "IA",
        "zip": "52556"
      },
      "bio": "Skilled software developer with more than 5 years of intensive experience leading the development of comprehensive solutions for a variety of industries and purposes. Proven history of using modern Software Development Life Cycle methodologies, design patterns, object-oriented techniques, and industry standards to deliver robust, reusable code in the form of efficient, high-performing systems which meet all project requirements. Expertise covers a broad range of applications using C#, VB.NET, NodeJS, JavaScript, React, Angular, My SQL, MongoDB, and RESTful services.",
      "contactmessage": "I have been working in telecommunication and IT infrastructure for many years. I have experience in many positions. When I was networking Engineer, I experience in setup, deploying service for the customers of my company. I good at almost network technology",
      "description": "SOFTWARE ENGINEER",
      "email": "tamnguyen5676@gmail.com",
      "github": "https://ngoctamnguyen.github.io/",
      "image": "profilepic.jpg",
      "name": "Tam Nguyen",
      "phone": "515-216-9233",
      "project": "https://ngoctamnguyen.github.io/",
      "resumedownload": "",
      "social": [
        {
          "className": "fa fa-facebook",
          "name": "facebook",
          "url": "https://www.facebook.com/nguyenngoc.tam.568"
        },
        {
          "className": "fa fa-linkedin",
          "name": "linkedin",
          "url": "https://www.linkedin.com/in/tamnguyen5676/"
        },
        {
          "className": "fa fa-github",
          "name": "github",
          "url": "https://ngoctamnguyen.github.io/"
        }
      ],
      "website": "www.tamnguyen.link"
    },
    
    "portfolio": {
      "projects": [
        {
          "title": "Website",
          "category": "Website",
          "image": "01.jpg",
          "url": "tamnguyen.link"
        },
        {
          "title": "Conigto",
          "category": "",
          "image": "02.jpg",
          "url": ""
        },
        {
          "title": "Lambda",
          "category": "AWS",
          "image": "03.jpg",
          "url": ""
        },
        {
          "title": "RDS",
          "category": "AWS",
          "image": "04.jpg",
          "url": ""
        }
      ]
    },

    "resume": {
      "education": [
        {
        "school": "Paris 6 University",
        "degree": "Master of Computer Network ",
        "description": "Compilers; Algorithms; Computer Network Structure; New Generation Network; Mobile Internet; Information System Management; Multimedia Service Quality; Routing and Router; Computer Network Security; Protocol Specialization and Validation; Business Information Systems",
        "graduated": "2008"
        },
        {
          "school": "Maharishi International University",
          "degree": "Master of Science in Computer Science",
          "description": "Object Oriented Programming; Algorithms; Web Application Programming in JavaScript; Server-Side Programming; Database Management; Software Engineering; Software Architecture; Mobile App Development; Web Application Development in React and Angular; Cloud Computing (AWS)",
          "graduated": "Jun 2023"
        }
      ],
      "skillmessage":"my skills",
      "skills": [
        {
          "name": "MS SQL Server",
          "level": "90%"
        },
        {
          "name": "MS Integration Services",
          "level": "80%"
        },
        ,
        {
          "name": "Tableau",
          "level": "80%"
        },
        {
          "name": "NodeJS",
          "level": "90%"
        },
        {
          "name": "JavaScript",
          "level": "90%"
        },
        {
          "name": "React",
          "level": "90%"
        },
        {
          "name": "Angular",
          "level": "80%"
        },
        {
          "name": "MongoDB",
          "level": "90%"
        },
      ],
      "work": [
        {
          "company": "Minh Phat Telecom Technology Solutions",
          "description": "Backend Developer. Designed and implemented reporting for all applications using SQL Server Reporting Services. Created packages to extract, transform and load data from a variety of data sources using MS Integration Services. Designed and implemented dashboards for all applications using MS Power BI. Designed database models using MS Visio and LucidChart for projects.Implemented database structures using MS SQL Server and My SQL for projects. Example: create databases, tables, stored procedures, functions, views. Implemented database testing using tSQLt",
          "title": "Senior Software Engineer ",
          "years": "2014-2020"
        },
        {
          "company": "The Post Souvenir",
          "description": "Worked as an architect, designing modern POS applications using .NET/Visual Studio and SQL Server and report pages using JavaScript, HTML5, CSS3, React. Designed and developed POS application manages more than 10,000 items using .NET/Visual Studio.Designed and implemented database models using MS SQL Server.Developed reports for summarizing the shop's activities and sending reports via email at the end of every day. Created Dashboards to show Sales, Orders, Inventory in summary and details using Tableau. Used React.js for templating for faster compilation and developing reusable components. Implemented reports for administration on various subjects such as accounting, sales, and inventory. Worked on ReactJS Virtual DOM and React views, rendering using components that contain additional components called custom HTML tags. Used Redux for state management to keep the application’s entire state in one store. Used React-router to develop SPA and implemented React JS components, Forms, Events, Keys.",
          "title": "Senior Software Engineer",
          "years": "2015-2021"
        },
        {
          "company": "Vietnam Posts and Telecommunications Group (VNPT)",
          "description": "Designed, deployed, and maintained IT networks.Designed and implemented applications manage network equipment using Visual Basic and MS SQL Server. Implemented script to auto configurate network equipment. Designed and deployed Local Area Network and Wide Area Network for multi-point connection depending on the client’s business. Installed Router, Switch; Cabling; Implemented configurations for all equipment. Monitored and Maintenance Local Area Network and Wide Area Network.",
          "title": "Senior Engineer",
          "years": "2005-2014"
        }
      ]
    }

    
  }
  
  export default resumeData