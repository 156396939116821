import { useState } from "react";
import { Fade, Slide } from "react-reveal";
import axios from "axios";

export default function Email() {

    const [showMessage, setShowMessage] = useState(false)

    const [message, setMessage] = useState({
        GuestName: "",
        Email: "",
        Phone: "",
        MessageTitle: "",
        Message: "",
    });

    function checkInfo(message, e) {
        e.preventDefault()

        console.log(message)
        try {
            axios.post('https://api.tamnguyen.link/messages', message)
                .then(() => {
                    console.log("Send message successfully! ")
                    setShowMessage(true)
                });
        } catch (e) {
            console.log(e);
        }
    }

    function cleanForm() {
        setMessage({
            GuestName: "",
            Email: "",
            Phone: "",
            MessageTitle: "",
            Message: "",
        })
    }

    function handleChanged(e) {
        setMessage({ ...message, [e.target.name]: e.target.value });
    }

    return (
        <>
            <section className="vh-100" id="email">
                <Fade bottom duration={1000}>
                    <div className="row section-head">
                        <div className="ten columns">
                            <p className="lead" ><h2 style={{color: 'gray'}}>Contact Me</h2></p>
                        </div>
                    </div>
                </Fade>

                <div className="row">
                    <Slide left duration={2000}>
                        <div className="eight columns">
                            <form className="mx-1 mx-md-4" onSubmit={(e) => checkInfo(message, e)}>

                                <div>
                                    {/* <label htmlFor="GuestName" style={{color: 'gray'}}>
                                        Name <span className="required">*</span>
                                    </label> */}
                                    <input
                                        className="required"
                                        type="text"
                                        defaultValue=""
                                        size="35"
                                        id="GuestName"
                                        name="GuestName"
                                        placeholder="Your name here..."
                                        onChange={(e) => handleChanged(e)}
                                    />
                                </div>

                                <div>
                                    {/* <label htmlFor="Email" style={{color: 'gray'}}>
                                        Email <span className="required">*</span>
                                    </label> */}
                                    <input
                                        type="text"
                                        defaultValue=""
                                        size="35"
                                        id="Email"
                                        name="Email"
                                        placeholder="Email..."
                                        onChange={(e) => handleChanged(e)}
                                    />
                                </div>

                                <div>
                                    {/* <label htmlFor="Phone" style={{color: 'gray'}}>
                                        Phone <span className="required">*</span>
                                    </label> */}
                                    <input
                                        type="text"
                                        defaultValue=""
                                        size="35"
                                        id="Phone"
                                        name="Phone"
                                        placeholder="Phone number..."
                                        onChange={(e) => handleChanged(e)}
                                    />
                                </div>


                                <div>
                                    {/* <label htmlFor="MessageTitle" style={{color: 'gray'}}>Subject</label> */}
                                    <input
                                        type="text"
                                        defaultValue=""
                                        size="35"
                                        id="MessageTitle"
                                        name="MessageTitle"
                                        placeholder="Subject..."
                                        onChange={(e) => handleChanged(e)}
                                    />
                                </div>

                                <div>
                                    {/* <label htmlFor="Message" style={{color: 'gray'}}>
                                        Message <span className="required">*</span>
                                    </label> */}
                                    <textarea
                                        cols="70"
                                        rows="5"
                                        id="Message"
                                        name="Message"
                                        placeholder="Message..."
                                        onChange={(e) => handleChanged(e)}
                                    ></textarea>
                                </div>
                                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                    <button 
                                        type="submit" 
                                        className="btn btn-primary btn-lg" 
                                        style={{borderColor: 'green'}}
                                        onClick={{ cleanForm }}>
                                            Send message
                                    </button>
                                </div>
                                {showMessage ? <p>{"Send message to Tam Nguyen successfully, Thank you!"}</p> : ""}

                            </form>
                        </div>
                    </Slide>

                </div>
            </section>

        </>
    )
}